// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-js": () => import("./../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-intro-mdx": () => import("./../../src/pages/articles/intro.mdx" /* webpackChunkName: "component---src-pages-articles-intro-mdx" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

