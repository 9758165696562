import React, { useState } from "react";
import tw from "twin.macro";
import { has, values, filter, length, pipe } from "ramda";

// type Problem = {
//    question: string,
//    choices: Choice[],
// }
//
// type Choice = string | { answer: string }
//
// Problem: Problem[]
//

const choiceValue = (choice) =>
  has("answer")(choice) ? choice.answer : choice;

const score = pipe(values, filter(has("answer")), length);

const Choice = ({ choice, isSelected, onClick }) => (
  <button
    onClick={onClick}
    css={[
      tw`block w-full py-4 px-10 mt-4 rounded border border-gray-400 border-dotted hover:border-solid hover:bg-gray-100`,
      isSelected && tw`bg-gray-200 hover:bg-gray-300`
    ]}
  >
    {choiceValue(choice)}
  </button>
);

const Quiz = ({ problems }) => {
  const [currentProblem, setCurrentProblem] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});

  const problem = problems[currentProblem];
  const selectedChoice = selectedChoices[currentProblem];
  const setSelectedChoice = (problem, selectedChoice) =>
    setSelectedChoices((choices) => ({
      ...choices,
      [problem]: selectedChoice
    }));

  return (
    <div tw="relative w-full flex justify-center rounded rounded-t-none overflow-hidden shadow-lg my-10 p-5 lg:p-10">
      <div tw="absolute top-0 left-0 right-0 h-1 bg-gray-400">
        <div
          tw="transition-all duration-500 ease-in-out h-full bg-teal-600 animate-pulse"
          css={{ width: `${(100 * currentProblem) / problems.length}%` }}
        />
      </div>
      <div tw="px-0 md:px-6 py-4 w-full lg:w-5/6">
        {currentProblem < problems.length ? (
          <>
            <div tw="font-heading text-xl text-center mb-8">
              {problem.question}
            </div>
            <div tw="text-gray-700 text-base">
              {problem.choices.map((choice) => {
                const onClick = () => {
                  setSelectedChoice(currentProblem, choice);
                  setCurrentProblem(currentProblem + 1);
                };

                return (
                  <Choice
                    key={`${problem.question}-${choiceValue(choice)}`}
                    choice={choice}
                    onClick={onClick}
                    isSelected={selectedChoice === choice}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <div tw="flex flex-col justify-center text-center">
            <div tw="font-heading text-4xl">คุณได้คะแนน</div>
            <div tw="font-heading py-5 mb-5">
              <span tw="text-5xl">{score(selectedChoices)}</span>
              <span tw="text-sm"> / {problems.length}</span>
            </div>
            <button
              tw="font-heading py-4 px-5 mt-4 mx-10 rounded border border-gray-400 border-dotted hover:border-solid hover:bg-gray-100"
              onClick={() => {
                setCurrentProblem(0);
                setSelectedChoices({});
              }}
            >
              ลองอีกครั้ง
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;
